import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { graphql, useStaticQuery } from "gatsby"

import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"

import TeamDescription from "../components/team-description"
import SteamWidget from "../components/steam-widget"

const IndexPage = () => {

  const { background } = useStaticQuery(
      graphql`
          query {
              background: file(relativePath: {eq: "background.webp"}) {
                  childImageSharp {
                  gatsbyImageData(
                      width: 3420,
                      placeholder: BLURRED,
                      formats: [AUTO, WEBP, AVIF],
                      quality: 100,
                      webpOptions: {quality: 100}
                  )
                  }
              }
          }
      `
  )

  const pluginImage = getImage(background)
  const bgImage = convertToBgImage(pluginImage)

  const header="Website designed by Joseph Riches";
  console.log('-'.repeat(header.length));
  console.log(header);
  console.log('-'.repeat(header.length));

  return (
    <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        style={{backgroundPosition:"top"}}
      >
        <Layout>
          <Seo title="Robust Games"/>

          <div className="flex-container title-parent">
            <h1 className="title">Robust Games is an indie team based in London, UK, co-founded by two brothers</h1>
          </div>

          <TeamDescription></TeamDescription>

          <SteamWidget></SteamWidget>

        </Layout>
      </BackgroundImage>
  )
}

export default IndexPage

import React from 'react'
import { IoLogoTwitter } from "react-icons/io"
import { StaticImage } from "gatsby-plugin-image"

const TeamDescription = () => {
    return (
        <section className="team-parent">
            <div className="flex-container">
              <div className="team-container flex-item">

                <div className="team-image">
                  <StaticImage
                    src="../images/adam.png"
                    alt="A picture of Adam Riches"
                    placeholder="BLURRED"
                    layout="fixed"
                    width={100}
                    height={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    style={{padding: `1em`, border: `2px #fcb732 solid`, borderRadius: `100%`}}
                  />
                </div>

                <div className="team-text">
                  <h1 className="team-title">Adam Riches - <span className="roles">Creative Director</span>
                  <span className="team-icons"><a href="https://twitter.com/Supernorn" aria-label="Link to Adam's twitter" target="_blank" rel="noopener noreferrer"><IoLogoTwitter/></a></span>
                  </h1>
                  <p className="description">Before co-founding Robust Games, Adam was an artist and animator at Chucklefish. With over seven years experience in the games industry, his work can be found in award winning titles such as Wargroove, Starbound &amp; Kingdom Two Crowns.</p>
                </div>
                
              </div>
              <div className="flex-item">
              <div className="team-container flex-item">

                <div className="team-image">
                  <StaticImage
                    src="../images/joe.png"
                    alt="A picture of Joseph Riches"
                    placeholder="BLURRED"
                    layout="fixed"
                    width={100}
                    height={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    style={{padding: `1em`, border: `2px #fcb732 solid`, borderRadius: `100%`}}
                  />
                </div>

                <div className="team-text">
                  <h1 className="team-title">Joseph Riches - <span className="roles">Technical Director</span>
                  <span className="team-icons"><a href="https://twitter.com/gamedevjoe" aria-label="Link to Joseph's twitter" target="_blank" rel="noopener noreferrer"><IoLogoTwitter/></a></span>
                  </h1>
                  <p className="description">Before co-founding Robust Games, Joseph was a software developer, with over five years experience developing Unity apps and full-stack web applications in the educational games market.</p>
                </div>

                </div>
              </div>

            </div>
          </section>
    )
}

export default TeamDescription
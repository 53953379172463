import * as React from "react"

const SteamWidget = () => {
    return (
        <div className="flex-container widget-parent">
          <div className="steam-widget">
            <iframe title="steam" src="https://store.steampowered.com/widget/1709880/" frameBorder="0" width="100%" height="190"></iframe>	
          </div>
        </div>
    )
}

export default SteamWidget